import { useAppDispatch, useAppSelector } from "@/lib/redux/hooks";
import { Button } from "./styles";
import { openTab, close } from "@/lib/redux/desktopMenu/desktopMenuSlice";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { AppLinkProps, pageMatchesPath } from "@/components/AppLink";

type InternalLink = AppLinkProps & {
  _type: "internalLink";
};

type ExternalLink = {
  _type: "externalLink";
  url: string;
  blank: boolean;
};

type Menu = {
  _type: "menu";
  items: (InternalLink | ExternalLink)[];
};

type Props = {
  tab: {
    _key: string;
    title: string;
    content?: Array<Menu | InternalLink | ExternalLink>;
  };
};

const MenuTab: React.FC<Props> = (props) => {
  const { tab } = props;

  const menuIsOpen = useAppSelector((state) => state.desktopMenu.isOpen);
  const activeTab = useAppSelector((state) => state.desktopMenu.activeTab);
  const dispatch = useAppDispatch();
  const { asPath } = useRouter();

  const subPagesMatchesPath = useMemo(() => {
    if (!tab?.content) return false;
    if (!(tab.content instanceof Array)) return false;

    for (const content of tab.content) {
      /**  Applies matching logic to an internalLink doc */
      const docMatchesCurrentPage = (doc: any) => {
        if (doc?._type === "internalLink") {
          return pageMatchesPath(doc.page, asPath);
        }

        // investors si trova dentro un menu
        if (doc?._type === "externalLink") {
          return doc?.url.includes("investors") &&
          asPath.includes("investors");
        }
      };

      if (content?._type === "internalLink") {
        return pageMatchesPath(content.page, asPath);
      }

      if (content?._type === "menu") {
        const foundSomething = content?.items.some(docMatchesCurrentPage);
        // If a match is found, tab represents the page.
        if (foundSomething) return true;
      }
    }
    return false;
  }, [tab?.content, asPath]);

  // A tab is active when:
  //  a) It was clicked
  //  b) Matches the current page
  const isActiveTab = useMemo(
    () => activeTab?._key && tab?._key && activeTab._key === tab._key,
    [activeTab?._key, tab?._key]
  );

  if (typeof tab?.title !== "string") return null;
  if (typeof tab?._key !== "string") return null;

  return (
    <Button
      onClick={() => dispatch(isActiveTab ? close() : openTab(tab))}
      $menuIsOpen={menuIsOpen}
      $isActive={isActiveTab || subPagesMatchesPath}
    >
      {tab.title}
    </Button>
  );
};

export default MenuTab;
